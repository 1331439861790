<template>
    <div>
        <el-table
                :data="answerSheetInfo"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="email"
                    label="邮箱"
            >
            </el-table-column>
            <el-table-column label="更新时间">
                <template #default="scope">
                    {{dateFormat(scope.row.updateTime)}}
                </template>
            </el-table-column>
            <el-table-column
                    v-for="question in questions"
                    :key="question.id"
                    :prop="String(question.id)"
                    :label="question.answerName"
            >
            </el-table-column>
        </el-table>
    </div>

    <el-pagination
            small
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageData.size"
            layout="total, prev, pager, next"
            :total="pageData.totalElements"
    >
    </el-pagination>

</template>

<style lang="scss">
</style>

<script>
    import {getTemplateAnswerSheetInfo, getAllTemplateQuestionByFileId} from "../api/api";
    import {getAnswerSimpleText} from "../components/common/question";
    import {dateFormat} from "../components/common/commonUtil";

    export default {
        name: 'templateAnswerSheetCompleteInfo',
        props:['fileId'],
        mounted: function () {
            this.init();
        },
        data: function () {
            return {
                internalFileId: this.fileId ? this.fileId : this.$route.params.fileId,
                questions: [],
                answerSheetInfo: [],
                currentPage: 1,
                pageData: {
                    number: 1,
                    size: 10,
                    totalElements: 0,
                    totalPages: 1
                },
                dateFormat:dateFormat,
            };
        },
        methods: {
            init: function () {
                const self = this;
                getAllTemplateQuestionByFileId(self.internalFileId).then(res => {
                    self.questions = res.data.data;
                    return self.questions
                }).then(question => {
                    self.refreshData(self.currentPage -1,self.pageData.size)
                });
            },
            refreshData: function (page, pageSize) {
                getTemplateAnswerSheetInfo(this.internalFileId, page, pageSize).then(res => {
                    console.log(res)
                    this.pageData = res.data.data
                    const answerSheetsInfo = res.data.data.content;
                    const content = [];

                    for (let i = 0; i < answerSheetsInfo.length; i++) {
                        const answerSheetInfo = answerSheetsInfo[i];
                        let row = {};
                        if (answerSheetInfo.answers && answerSheetInfo.answers.length > 0) {
                            //如果有答案
                            for (let j = 0; j < answerSheetInfo.answers.length; j++) {
                                const  answer = answerSheetInfo.answers[j]
                                row[String(answer.questionId)] = getAnswerSimpleText(answer);
                            }
                        }
                        //给没有答案的填上一个空值
                        this.questions.forEach(q=>{
                            if(!row[String(q.id)]){
                                row[String(q.id)] = '';
                            }
                        })
                        Object.assign(row,answerSheetInfo.sheet)
                        content.push(row);
                    }
                    this.answerSheetInfo = content
                })
            },
            /**
             * 页数改变监听
             */
            handleCurrentChange: function (currentPage) {
                this.refreshData(currentPage - 1, this.pageData.size)
            },
        },
        components: {}
    };
</script>
