<template>
    <div class="header">
        <div class="left-tool">
          <div class="logo-container"><img src="../assets/images/metasotalog.png" class="logo"></div>
          <div class="header-close" v-on:click.stop="back">
            <img src="../assets/return.png"/><span style="font-size: 14px">返回</span>
          </div>
            <slot name="left"></slot>
        </div>
        <div class="center-tool">
            <slot name="center"></slot>
        </div>
        <div class="right-tool">
          <span class="saving-text">{{savingText}}</span>
          <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        props: ['savingText'],
        data:function(){
            return {
            }
        },
        methods: {
            back() {
                this.$router.back()
            }
        },
    }
</script>

<style scoped>
    .header{
      position: static;
      height: 48px;
      width: 100%;
      box-shadow: 0px 5px 10px 0px rgba(8, 24, 60, 0.1);
    }
    .header-close{
      color: white;
      position: absolute;
      width: 76px;
      height: 32px;
      left: 56px;
      display: inline-block;
      background-color: #454D66;
      border-radius: 16px;
      text-align: left;
      margin-top: 8px;
      line-height: 32px;
      cursor: pointer;
    }
    .header-close img{
      width: 24px;
      height: 24px;
      margin-left: 10px;
      vertical-align: middle;
    }

    .left-tool {
      position: absolute;
      height: 100%;
      left: 0;
      line-height: 48px;
    }

    .center-tool {
        position: absolute;
        height: 100%;
        left: 150px;
        /*transform: translateX(-50%);*/
    }

    .right-tool {
      position: absolute;
      height: 100%;
      right: 35px;
      line-height: 48px;
    }

    .saving-text {
      color:gray;
      vertical-align: middle;
      margin-right: 27px;
    }

    .logo-container {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      margin-top: 4px;
      text-align: center;
      margin-left: 8px;
      position: absolute;
    }

    .logo {
      width: 40px;
      height: 40px;
    }
</style>
