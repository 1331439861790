<template>
    <el-container style="height: 100vh">
        <el-header >
            <Header>
            </Header>
        </el-header>
        <el-container>
            <el-main style="overflow: visible;">
                <el-tabs v-model="activeTab" @tab-click="handleClick">
                    <el-tab-pane label="问答管理" name="manager">
                        <template-answer-sheet-simple-info :file-id="internalFileId"/>
                    </el-tab-pane>
                    <el-tab-pane label="问答信息" name="info">
                        <template-answer-sheet-complete-info :file-id="internalFileId"/>
                    </el-tab-pane>
                </el-tabs>
            </el-main>
        </el-container>
    </el-container>

</template>

<script>
    import TemplateAnswerSheetCompleteInfo from "./TemplateAnswerSheetsCompleteInfo";
    import TemplateAnswerSheetSimpleInfo from "./TemplateAnswerSheetsSimpleInfo";
    import Header from "../components/Header";
    export default {
        name: "TemplateAnswerSheetInfo",
        props:['fileId'],
        data:function () {
            return {
                internalFileId: this.fileId ? this.fileId : this.$route.params.fileId,
                activeTab:'manager'
            }
        },
        components: {TemplateAnswerSheetSimpleInfo, TemplateAnswerSheetCompleteInfo,Header}
    }
</script>

<style scoped>
    .el-header{
        padding:0;
    }
</style>
