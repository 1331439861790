<template>
    <div>
        <el-table
                :data="answerSheets"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="email"
                    label="邮箱"
            >
            </el-table-column>

            <el-table-column
                    prop="sourceType"
                    label="提交客户端"
            >
                <template #default="scope">
                    {{formatSourceType(scope.row.sourceType)}}
                </template>
            </el-table-column>

<!--            <el-table-column label="初次提交">-->
<!--                <template #default="scope">-->
<!--                    {{dateFormat(scope.row.createTime)}}-->
<!--                </template>-->
<!--            </el-table-column>-->

            <el-table-column label="更新时间">
                <template #default="scope">
                    {{dateFormat(scope.row.updateTime)}}
                </template>
            </el-table-column>



            <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                <template #default="scope">
<!--                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>-->
                    <el-button type="text" size="small" @click="downloadDoc(scope.row.fileId,scope.row.id)" :loading="downloading">下载</el-button>
                    <el-button type="text" size="small" @click="deleteAnswerSheet(scope.row.fileId,scope.row.id)"  :loading="deleting">删除</el-button>
                </template>
            </el-table-column>

        </el-table>
    </div>

    <el-pagination
            small
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageData.size"
            layout="total, prev, pager, next"
            :total="pageData.totalElements"
    >
    </el-pagination>

</template>

<style lang="scss">
</style>

<script>
    import {deleteAnswerSheet, getTemplateAnswerSheets} from "../api/api";
    import {formatSourceType} from "../constant/question";
    import {dateFormat, getDownloadUrlByAnswerSheetId} from "../components/common/commonUtil";

    export default {
        name: 'templateAnswerSheetSimpleInfo',
        props:['fileId'],
        mounted: function () {
            this.init();
        },
        data: function () {
            return {
                internalFileId: this.fileId ? this.fileId : this.$route.params.fileId,
                answerSheets: [],
                currentPage: 1,
                pageData: {
                    number: 1,
                    size: 10,
                    totalElements: 0,
                    totalPages: 1
                },
                formatSourceType:formatSourceType,
                downloading: false,
                deleting: false,
                dateFormat:dateFormat,
            };
        },
        methods: {
            init: function () {
                const self = this;
                self.refreshData(self.currentPage -1,self.pageData.size)
            },
            refreshData: function (page, pageSize) {
                page = page === undefined ? this.pageData.number : page;
                pageSize = pageSize === undefined ? this.pageData.size : pageSize;
                getTemplateAnswerSheets(this.internalFileId, page, pageSize).then(res => {
                    console.log(res)
                    this.pageData = res.data.data
                    this.answerSheets = res.data.data.content;
                })
            },
            //下载模板文档
            downloadDoc:function(fileId,sheetId){
                // this.downloading = true;
                location.href = getDownloadUrlByAnswerSheetId(fileId,sheetId)
                // setTimeout(() => {
                //     this.downloading = false;
                // }, 1000);
            },
            //删除某一份答卷
            deleteAnswerSheet: function (fileId,sheetId) {
                this.deleting = true;
                deleteAnswerSheet(fileId,sheetId).then(res => {
                    if (res.data.code === 0) {
                        this.refreshData()
                    } else {
                        this.$message.error('删除失败')
                    }
                }).catch(error => {
                    console.error(error);
                    this.$message.error('删除失败')
                }).finally(() => {
                    this.deleting = false;
                });
            },
            /**
             * 页数改变监听
             */
            handleCurrentChange: function (currentPage) {
                this.refreshData(currentPage - 1, this.pageData.size)
            },
        },
        components: {}
    };
</script>
